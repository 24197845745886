import {Button, Descriptions, Dropdown, Layout, Image, PageHeader, Space, Table, Menu, Tag, message, Input} from 'antd';
import React from 'react';
import {DownOutlined, SearchOutlined} from "@ant-design/icons";
import sendApi from "../Configs/Api";
import Highlighter from "react-highlight-words";
import ViewAdmin from "../Components/AdminManagement/ViewAdmin";
import CreateNewAdmin from "../Components/AdminManagement/CreateNewAdmin";


function AdminManagement() {

    const { Content }                       = Layout;
    const [getAdmins,setAdmins]             = React.useState([]);
    const [getAdminId,setAdminId]               = React.useState(null);
    const [getCreateDraw,setCreateDraw]     = React.useState(false);
    const [getViewDraw,setViewDraw]         = React.useState(false);
    const [getLoading,setLoading]           = React.useState(false);

    const [searchText, setSearchText]                   = React.useState('');
    const [searchedColumn, setSearchedColumn]           = React.useState('');
    const searchInput                                   = React.useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 8}}>
                <Input ref={searchInput} placeholder={`Aranacak Bir İçerik Giriniz...`} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}  style={{marginBottom: 8, display: 'block'}}/>
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{width: 90}}>
                        Bul
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        handleSearch(selectedKeys, confirm, dataIndex);
                        setSearchText('');
                    }} size="small" style={{width: 90}}>Temizle</Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0}} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text)
    });

    const columns = [
        {
            title: 'İsim Soyisim',
            dataIndex: 'fullName',
            key: 'fullName',
            ...getColumnSearchProps('fullName')
        },
        {
            title: 'E-Posta',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'time',
            key: 'time'
        },
        {
            title: 'İşlemler',
            key: 'action',
            render: (_, record) => (
                <Button color={"danger"} onClick={()=>{
                    setAdminId(record.id);
                    setViewDraw(true);
                }}>İncele</Button>
            ),
        },
    ];

    const getAdminsServer = () => {

        setLoading(true);
        sendApi("/v1/frontend/admin/list")
            .then(result => {

                setAdmins([]);

                if(result.status === "success"){

                    result.data.map(item=>setAdmins(getAdmins => [...getAdmins,{
                        id: item.id,
                        fullName: item.fullName,
                        email: item.email,
                        time: item.time
                    }]));

                }else{message.error(result.message); }

                setLoading(false);

            })
            .catch(error => {message.error(error.message)})

    }

    React.useEffect(getAdminsServer,[]);


    return (
        <>

            <Content className="site-layout" style={{ padding: '0 10px', marginTop: 10 }}>
                <PageHeader
                    className="site-page-header-responsive"
                    onBack={() => window.history.back()}
                    title="Yetkili Yönetimi"
                    subTitle="Yetkili yönetimi ile yeni yetkili ekleyip, ekli yetkilileri güncelleyebilirsiniz."
                    extra={[<Dropdown.Button
                        icon={<DownOutlined />}
                        loading={0}
                        overlay={<Menu
                            items={[
                                {
                                    label: 'Uygulama Oluştur',
                                    key: '1',
                                    onClick: (item) =>  setCreateDraw(true)
                                }
                            ]}
                        />}
                        onClick={(item) => null}>
                        İşlem Yap
                    </Dropdown.Button>]}
                    footer={<Table loading={getLoading} columns={columns} dataSource={getAdmins} />}>
                    <div className="content">
                        <div className="main">
                            <Descriptions size="small" column={3}>
                                <Descriptions.Item label="Toplam Yetkili">{getAdmins.length}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </div>
                </PageHeader>
            </Content>

            <ViewAdmin
                visible={getViewDraw}
                id={getAdminId}
                result={()=>{
                    getAdminsServer();
                }}
                onClose={()=>{
                    setViewDraw(false);
                }}
            />

            <CreateNewAdmin
                visible={getCreateDraw}
                result={()=>{
                    getAdminsServer();
                    setCreateDraw(false);
                }}
                onClose={()=>{
                    setCreateDraw(false);
                }}
            />
        </>
    );
}

export default AdminManagement;
