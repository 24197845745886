import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    message,
    Progress,
    Result,
    Row,
    Select,
    Space,
    Upload
} from 'antd';
import React, { useState } from 'react';
import sendApi from "../../Configs/Api";
import moment from "moment";
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

const CreateNewCard = (props) => {

    const [form]                                        = Form.useForm();
    const [fileListCover, setFileListCover]             = useState([]);
    const [loading, setLoading]                         = useState(false);
    const [getCustomers,setCustomers]                   = React.useState([]);
    const [percentView, setPercentView]                 = React.useState(0);

    const { Option } = Select;

    const onChangeStatus = (value) => {};

    const onFinish = (values) => {

        setLoading(true);

        sendApi("/v1/frontend/card/create",{
            title:      values.title,
            customer:   values.customer,
            date:       values.date.unix(),
            cover:      values.cover.file
        },(inCome)=>{
            if(inCome > 0){
                setPercentView(inCome);
            }
            if(inCome >= 100 && !loading){
                setPercentView(0);
            }
        }).then(result=> {
            if(result.status === "success"){
                setLoading(false);
                message.success("Kayıt Eklendi");
                props?.result(true,result.data.serial);
                form.resetFields();
            }else{
                message.error(result.message);
                props?.result(false,null);
            }
        }).catch(error=>{
            message.error(error.message);
            props?.result(true,null);
            setLoading(false);
        });

    };

    const propsCover = {
        accept:".jpg,.JPEG,.JPEG,.jpeg,.png,.PNG",
        style:{width:"100%"},
        maxCount:1,
        onRemove: (file) => {
            const index = fileListCover.indexOf(file);
            const newFileList = fileListCover.slice();
            newFileList.splice(index, 1);
            setFileListCover(newFileList);
        },
        beforeUpload: (file) => {
            setFileListCover([...fileListCover, file]);
            return false;
        },
        fileListCover,
    };

    React.useEffect(()=>{
        sendApi("/v1/frontend/customer/list")
            .then((result)=>{

                if(result.status === "success"){

                    setCustomers([]);
                    result.data.map(item=>setCustomers(getCustomers => [...getCustomers,{
                        serial: item.serial,
                        fullName: item.fullName
                    }]));

                }else{
                    message.error(result.message);
                }

            }).catch((error)=>{
            message.error(error.message);
        });
    },[])

    return (
        <>

            <Drawer
                title="Kart Oluştur"
                width={720}
                onClose={props.onClose}
                visible={props.visible}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button type={"danger"} onClick={()=>{ form.resetFields() }}>Formu Sıfırla</Button>
                        <Button onClick={()=>{ form.submit() }} type="primary" loading={loading}>Oluştur</Button>
                    </Space>
                }>

                { loading &&  <Result icon={<Progress  type="circle"  strokeColor={{ '0%': '#108ee9',  '100%': '#87d068'}}  percent={percentView}/>}  title="Kart Yükleniyor, Lütfen Bekleyiniz." />}


                {
                    !loading &&
                    <Form  form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="title" label="Başlık" rules={[{required: true, message:"Başlık Giriniz"}]}>
                                    <Input placeholder={"Başlık"}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="customer" label="Hedef Kitle" rules={[{required: true, message:"Lütfen hedef kitle seçiniz"}]}>
                                    <Select showArrow showSearch     filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}  placeholder="Seçim yapınız" onChange={onChangeStatus} allowClear>
                                        <Option value="all">Tüm Müşteriler</Option>
                                        {getCustomers.map(item => <Option key={item.serial} value={item.serial}>{item.fullName}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="date" label="Gösterim Tarihi" rules={[{required: true, message:"Lütfen gösterim tarihi seçiniz" }]}>
                                    <DatePicker
                                        disabledDate={(current) => {
                                            let customDate = moment().format("YYYY-MM-DD");
                                            return current && current < moment(customDate, "YYYY-MM-DD");
                                        }}
                                        locale={locale}
                                        placeholder={"Tarih Seçiniz"}
                                        style={{width:"100%"}}
                                        onChange={()=>{}}
                                        format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item style={{width:"100%"}} name="cover" label="Kart Görseli" rules={[{required: true, message:"Lütfen kapak görseli seçiniz"}]}>
                                    <Upload  {...propsCover}>
                                        <Button style={{width:"100%"}} icon={<UploadOutlined />}>Kart Seçiniz</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                }
            </Drawer>
        </>
    );
};


export default CreateNewCard;