import {Avatar, Button, Descriptions, Dropdown, Layout, Menu, message, PageHeader, Table, Tag} from 'antd';
import React from 'react';
import CreateNewCategory from "../Components/EducationCategories/CreateNewCategory";
import {DownOutlined, UserOutlined} from "@ant-design/icons";
import DetailsCategory from "../Components/EducationCategories/DetailsCategory";
import sendApi from "../Configs/Api";

function EducationCategories() {

    const { Content }                           = Layout;
    const [createModal,setCreateModal]          = React.useState(false);
    const [detailsModal,setDetailsModal]        = React.useState(false);
    const [categoryContent,setCategoryContent]  = React.useState(null);
    const [getLoading,setLoading]               = React.useState(null);
    const [getData,setData]                     = React.useState([]);

    const columns = [
        {
            title: 'Eğitim Başlığı',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Yönetici',
            dataIndex: 'admin',
            key: 'admin',
            render: (_, record) => (
                <>
                    <Avatar size={30} icon={<UserOutlined />}  style={{marginRight:8}} /> {record.admin.fullName}
                </>
            )
        },
        {
            title: 'Yayın Durumu',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                <>
                    {record.status === "active" ? (<Tag color={"green"}>YAYINDA</Tag>) : (<Tag color={"volcano"}>PASİF</Tag>)}
                </>
            ),
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'time',
            key: 'time'
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (_, record) => (
                <Button type="primary" onClick={()=>{
                    setCategoryContent(record.serial)
                    setDetailsModal(true);
                }}>Detay Git</Button>
            ),
        },
    ];

    const pageHeaderMenuItem = [
        {
            label: 'Kategori Oluştur',
            key: '1',
            onClick: (item) =>  setCreateModal(true)
        }
    ];

    const pageHeaderMenu =
        <Dropdown.Button
            icon={<DownOutlined />}
            loading={0}
            overlay={<Menu  items={pageHeaderMenuItem}/>}
            onClick={(item) => null}>İşlem Seçin</Dropdown.Button>;

    const getCategories = () => {
        setLoading(true);
        sendApi("/v1/frontend/category/list")
            .then(result=>{
                if(result.status === "success"){
                    setData([]);
                    result.data.map(item=>{
                        setData(getData => [...getData,{
                            serial: item.serial,
                            title: item.title,
                            action: item.serial,
                            admin: item.admin,
                            status: item.status,
                            time: item.time
                        }])
                    })
                }else{
                    message.error(result.message)
                }
                setLoading(false);
            })
            .catch(err=>{message.error(err.message)})

    }

    React.useEffect(getCategories,[]);

    return (
    <>
        <Content className="site-layout" style={{ padding: '0 10px', marginTop: 10 }}>
            <PageHeader
                className="site-page-header-responsive"
                onBack={() => window.history.back()}
                title="Eğitim Yönetimi"
                subTitle="Bu alanda eğitim kategorileri ve içeriklerini yönetebilirsiniz."
                extra={pageHeaderMenu}
                footer={<Table columns={columns} dataSource={getData} loading={getLoading} /> }>
                <div className="content">
                    <div className="main">
                        <Descriptions size="small" column={3}>
                            <Descriptions.Item label="Toplam Kategori">{getData.length}</Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>
            </PageHeader>
        </Content>

        <CreateNewCategory
            visible={createModal}
            serial={categoryContent}
            result={(status,serial)=>{
                if(status){
                    getCategories();
                    setCreateModal(false);
                    setCategoryContent(serial);
                    setDetailsModal(true);
                }
            }}
            onClose={()=>{
            setCreateModal(false);
            }}
        />

        <DetailsCategory
            visible={detailsModal}
            serial={categoryContent}
            onClose={()=>{
                setDetailsModal(false);
            }}
            result={()=>{
                getCategories();
            }}
        />


    </>
  );
}

export default EducationCategories;
