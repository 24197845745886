import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    message,
    Progress,
    Result,
    Row,
    Select,
    Space,
    Upload
} from 'antd';
import React, { useState } from 'react';
import sendApi from "../../Configs/Api";
import moment from "moment";
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

const CreateNewApp = (props) => {

    const [form]                                        = Form.useForm();
    const [loading, setLoading]                         = useState(false);

    const { Option } = Select;
    const { Password } = Input;

    const onChangeStatus = (value) => {};

    const onFinish = (values) => {

        setLoading(true);

        sendApi("/v1/frontend/app/create",{
            title:      values.title,
            server:     values.server,
            status:       values.status
        }).then(result=> {
            if(result.status === "success"){
                message.success("Kayıt Eklendi");
                props?.result(true);
                form.resetFields();
            }else{
                message.error(result.message);
            }

            setLoading(false);

        }).catch(error=>{
            message.error(error.message);
            setLoading(false);
        });

    };

    return (
        <>

            <Drawer
                title="Uygulama Oluştur"
                width={720}
                onClose={props.onClose}
                visible={props.visible}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={()=>{ form.submit() }} type="primary" loading={loading}>Oluştur</Button>
                    </Space>
                }>

                {
                    !loading &&
                    <Form  form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="title" label="Başlık" rules={[{required: true, message:"Uygulama Başlığı Giriniz"}]}>
                                    <Input placeholder={"Veri Giriniz..."}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="server" label="Sunucu Adresi" rules={[{required: true, message:"Uygulama Sunucu Adresi Giriniz"}]}>
                                    <Input type={"url"} placeholder={"Veri Giriniz..."}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="status" label="Yayın Durumu" rules={[{required: true, message:"Lütfen Yayın Durumu Seçiniz"}]}>
                                    <Select showArrow showSearch     filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}  placeholder="Seçim yapınız" onChange={onChangeStatus} allowClear>
                                        <Option value="active">Aktif</Option>
                                        <Option value="passive">Pasif</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                }
            </Drawer>
        </>
    );
};


export default CreateNewApp;