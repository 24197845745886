import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Drawer, Form, Input, message, Row, Select, Space, Upload} from 'antd';
import React, { useState } from 'react';
import sendApi from "../../Configs/Api";
const { Option } = Select;

const CreateNewCategory = (props) => {

    const [form] = Form.useForm();

    const [fileListLogo, setFileListLogo] = useState([]);
    const [fileListCover, setFileListCover] = useState([]);
    const [loading, setLoading] = useState(false);

    const { Option } = Select;

    const onChangeStatus = (value) => {};

    const onFinish = (values) => {
        setLoading(true);
       sendApi("/v1/frontend/category/create",{
           title: values.title === undefined ? "" : values.title,
           status: values.status === undefined ? "" : values.status,
           certificate_1: values.certificate1 === undefined ? "" : values.certificate1,
           certificate_2: values.certificate2 === undefined ? "" : values.certificate2,
           certificate_3: values.certificate3 === undefined ? "" : values.certificate3,
           logo: values.logo === undefined ? "" : values.logo.file,
           cover: values.cover === undefined ? "" : values.cover.file
       }).then(result=> {
           setLoading(false);
           if(result.status === "success"){
               message.success("Kayıt Eklendi");
               form.resetFields();
               props?.result(true,result.data.serial);
           }else{
               message.error(result.message);
               props?.result(false,null);
           }
       }).catch(error=>{
                setLoading(false);
               message.error(error.message);
               props?.result(true,null);
           });

    };

    const propsLogo = {
        maxCount:1,
        onRemove: (file) => {
            const index = fileListLogo.indexOf(file);
            const newFileList = fileListLogo.slice();
            newFileList.splice(index, 1);
            setFileListLogo(newFileList);
        },
        beforeUpload: (file) => {
            setFileListLogo([...fileListLogo, file]);
            return false;
        },
        fileListLogo,
    };

    const propsCover = {
        maxCount:1,
        onRemove: (file) => {
            const index = fileListCover.indexOf(file);
            const newFileList = fileListCover.slice();
            newFileList.splice(index, 1);
            setFileListCover(newFileList);
        },
        beforeUpload: (file) => {
            setFileListCover([...fileListCover, file]);
            return false;
        },
        fileListCover,
    };

    return (
        <>

            <Drawer
                title="Eğitim Kategorisi Oluştur"
                width={720}
                onClose={props.onClose}
                visible={props.visible}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        <Button type={"danger"} onClick={()=>{ form.resetFields() }}>Formu Sıfırla</Button>
                        <Button onClick={()=>{ form.submit() }} type="primary" loading={loading}>Oluştur</Button>
                    </Space>
                }>
                <Form  form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                    <Row gutter={16}>
                        <Col span={15}>
                            <Form.Item name="title" label="Başlık" rules={[{required: true, message:"Lütfen bir başlık giriniz"}]}>
                                <Input placeholder={"Lütfen bir başlık giriniz"}/>
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item name="status" label="Yayın Durumu" rules={[{required: true, message:"Lütfen yayın durumu seçiniz"}]}>
                                <Select placeholder="Seçim yapınız" onChange={onChangeStatus} allowClear>
                                    <Option value="active">Aktif</Option>
                                    <Option value="passive">Pasif</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="certificate1" label="Sertifika Alan 1" rules={[{required: true, message:"Lütfen sertifika alan 1 içeriği giriniz" }]}>
                                <Input showCount maxLength={75} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="certificate2" label="Sertifika Alan 2" rules={[{required: false, message:"Lütfen sertifika alan 2 içeriği giriniz" }]}>
                                <Input showCount maxLength={75} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="certificate3" label="Sertifika Alan 3" rules={[{required: false, message:"Lütfen sertifika alan 3 içeriği giriniz" }]}>
                                <Input showCount maxLength={75} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="logo" label="Logo Görseli" rules={[{required: true, message:"Lütfen logo görseli seçiniz" }]}>
                                <Upload {...propsLogo}>
                                    <Button icon={<UploadOutlined />}>Logo Seçiniz</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="cover" label="Kapak Görseli" rules={[{required: true, message:"Lütfen kapak görseli seçiniz"}]}>
                                <Upload {...propsCover}>
                                    <Button icon={<UploadOutlined />}>Kapak Seçiniz</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};


export default CreateNewCategory;