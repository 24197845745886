import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {
    Button,
    Col,
    Tabs,
    Drawer,
    Form,
    Input,
    message,
    Progress,
    Result,
    Row,
    Select,
    Space,
    Upload
} from 'antd';
import React, { useState } from 'react';
import sendApi from "../../Configs/Api";
import 'moment/locale/tr';
import ConfirmApiRequest from "../../Configs/ConfirmApiRequest";

const ViewApp = (props) => {

    const [form]                                        = Form.useForm();
    const [loading, setLoading]                         = useState(false);
    const [getData, setData]                         = useState({});

    const { Option } = Select;
    const { Password } = Input;
    const { TabPane } = Tabs;

    const onChangeStatus = (value) => {};

    const onFinish = (values) => {

        setLoading(true);

        sendApi("/v1/frontend/app/update",{
            id:          props.serial,
            title:       values.title,
            server:      values.server,
            status:      values.status,
        }).then(result=> {
            if(result.status === "success"){
                message.success("Uygulama Güncellendi");
                props?.result(true);
            }else{
                message.error(result.message);
            }

            setLoading(false);

        }).catch(error=>{
            message.error(error.message);
            setLoading(false);
        });

    };

    const getDataToApiServer = () => {

        setLoading(true);

        sendApi("/v1/frontend/app/details",{
            id: props.serial,
        }).then(result=> {
            if(result.status === "success"){
                message.success("Uygulama Verileri Hazırlandı");

                setData(result.data);

                form.setFields([
                    {
                        name:['title'],
                        value:result.data.title
                    },
                    {
                        name:['status'],
                        value:result.data.status
                    },
                    {
                        name:['server'],
                        value:result.data.server
                    }
                ])

                setLoading(false);

            }else{
                message.error(result.message);
            }


        }).catch(error=>{
            message.error(error.message);
            setLoading(false);
        });


    }

    React.useEffect(()=>{

        if(props?.visible){
            getDataToApiServer();
        }


    },[props.serial])

    return (
        <>

            <Drawer
                title="Uygulama Güncelleme"
                width={720}
                onClose={props.onClose}
                visible={props.visible}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <ConfirmApiRequest
                            buttonText={"Kaldır"}
                            server={"/v1/frontend/app/delete"}
                            params={{
                                id: props.serial
                            }}
                            result={()=>{
                                props?.result(true);
                                props.onClose(false);
                            }}
                        />
                        <Button onClick={()=>{ form.submit() }} type="primary" loading={loading}>Güncelle</Button>
                    </Space>
                }>

                <Tabs defaultActiveKey="1">
                    <TabPane tab="Ayarlar" key="1">
                        {
                            !loading &&
                            <Form  form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item name="title" label="Başlık" rules={[{required: true, message:"Uygulama Başlığı Giriniz"}]}>
                                            <Input placeholder={"Veri Giriniz..."}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="server" label="Sunucu Adresi" rules={[{required: true, message:"Uygulama Sunucu Adresi Giriniz"}]}>
                                            <Input type={"url"} placeholder={"Veri Giriniz..."}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="status" label="Yayın Durumu" rules={[{required: true, message:"Lütfen Yayın Durumu Seçiniz"}]}>
                                            <Select showArrow showSearch     filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}  placeholder="Seçim yapınız" onChange={onChangeStatus} allowClear>
                                                <Option value="active">Aktif</Option>
                                                <Option value="passive">Pasif</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </TabPane>
                    <TabPane tab="Lighthouse Test" key="2">
                        { getData?.server && <iframe src={"https://googlechrome.github.io/lighthouse/viewer/?psiurl=" + getData.server} frameBorder="0" width={670} height={550}></iframe>}
                    </TabPane>
                </Tabs>
            </Drawer>
        </>
    );
};


export default ViewApp;