import {Button, Descriptions, Dropdown, Layout, Image, PageHeader, Space, Table, Menu, Tag, message, Input} from 'antd';
import React from 'react';
import {DownOutlined, SearchOutlined} from "@ant-design/icons";
import {ContentServer} from "../Configs/Settings";
import sendApi from "../Configs/Api";
import moment from "moment";
import Highlighter from "react-highlight-words";
import CreateNewApp from "../Components/AppManagement/CreateNewApp";
import ViewApp from "../Components/AppManagement/ViewApp";


function AppManagement() {

    const { Content }                       = Layout;
    const [getApps,setApps]                 = React.useState([]);
    const [getAppId,setAppId]               = React.useState(null);
    const [getCreateDraw,setCreateDraw]     = React.useState(false);
    const [getViewDraw,setViewDraw]         = React.useState(false);
    const [getLoading,setLoading]           = React.useState(false);

    const [searchText, setSearchText]                   = React.useState('');
    const [searchedColumn, setSearchedColumn]           = React.useState('');
    const searchInput                                   = React.useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 8}}>
                <Input ref={searchInput} placeholder={`Aranacak Bir İçerik Giriniz...`} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}  style={{marginBottom: 8, display: 'block'}}/>
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{width: 90}}>
                        Bul
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        handleSearch(selectedKeys, confirm, dataIndex);
                        setSearchText('');
                    }} size="small" style={{width: 90}}>Temizle</Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0}} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text)
    });

    const columns = [
        {
            title: 'Başlık',
            dataIndex: 'title',
            key: 'title',
            ...getColumnSearchProps('title')
        },
        {
            title: 'Yetkili',
            dataIndex: 'admin',
            key: 'admin',
            render: (_, { admin }) => <Tag color={"processing"}>{admin}</Tag>,
        },
        {
            title: 'Yayın Durumu',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => <Tag color={status ? "success" : "error"}>{status ? "AKTİF" : "PASİF"}</Tag>,
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'time',
            key: 'time'
        },
        {
            title: 'İşlemler',
            key: 'action',
            render: (_, record) => (
                <Button color={"danger"} onClick={()=>{
                    setAppId(record.id);
                    setViewDraw(true);
                }}>İncele</Button>
            ),
        },
    ];

    const getAppsServer = () => {

        setLoading(true);
        sendApi("/v1/frontend/app/list")
            .then(result => {

                if(result.status === "success"){
                    setApps([]);
                    result.data.map(item=>setApps(getApps => [...getApps,{
                        id: item.id,
                        title: item.title,
                        admin: item.admin.fullName,
                        time: item.time,
                        status: item.status === "active",
                    }]));

                }else{message.error(result.message); }

                setLoading(false);

            })
            .catch(error => {message.error(error.message)})

    }

    React.useEffect(getAppsServer,[]);


    return (
        <>

            <Content className="site-layout" style={{ padding: '0 10px', marginTop: 10 }}>
                <PageHeader
                    className="site-page-header-responsive"
                    onBack={() => window.history.back()}
                    title="Uygulama Yönetimi"
                    subTitle="Bu alanda uygulama içi uygulama ekleme, düzenleme ve yönetme işlemlerini gerçekleştirebilirsiniz."
                    extra={[<Dropdown.Button
                        icon={<DownOutlined />}
                        loading={0}
                        overlay={<Menu
                            items={[
                                {
                                    label: 'Uygulama Oluştur',
                                    key: '1',
                                    onClick: (item) =>  setCreateDraw(true)
                                }
                            ]}
                        />}
                        onClick={(item) => null}>
                        İşlem Yap
                    </Dropdown.Button>]}
                    footer={<Table loading={getLoading} columns={columns} dataSource={getApps} />}>
                    <div className="content">
                        <div className="main">
                            <Descriptions size="small" column={3}>
                                <Descriptions.Item label="Toplam Kart">{getApps.length}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </div>
                </PageHeader>
            </Content>

            <ViewApp
                visible={getViewDraw}
                serial={getAppId}
                result={()=>{
                    getAppsServer();
                }}
                onClose={()=>{
                    setViewDraw(false);
                }}
            />

            <CreateNewApp
                visible={getCreateDraw}
                result={()=>{
                    getAppsServer();
                    setCreateDraw(false);
                }}
                onClose={()=>{
                    setCreateDraw(false);
                }}
            />
        </>
    );
}

export default AppManagement;
