import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    message,
    Progress,
    Result,
    Row,
    Select,
    Space,
    Upload
} from 'antd';
import React, { useState } from 'react';
import sendApi from "../../Configs/Api";
import moment from "moment";
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import ConfirmApiRequest from "../../Configs/ConfirmApiRequest";

const ViewAdmin = (props) => {

    const [form]                                        = Form.useForm();
    const [loading, setLoading]                         = useState(false);

    const { Option } = Select;
    const { Password } = Input;

    const onChangeStatus = (value) => {};

    const onFinish = (values) => {

        setLoading(true);

        sendApi("/v1/frontend/admin/update",{
            id:       props.id,
            fullName: values.fullName,
            email:    values.email,
            phone:    values.phone,
            password: values.password,
        }).then(result=> {
            if(result.status === "success"){
                message.success("Yetkili Güncellendi");
                props?.result(true);
            }else{
                message.error(result.message);
            }

            setLoading(false);

        }).catch(error=>{
            message.error(error.message);
            setLoading(false);
        });

    };

    const getDataToApiServer = () => {

        sendApi("/v1/frontend/admin/details",{
            id: props.id,
        }).then(result=> {

            if(result.status === "success"){

                setLoading(true);

                message.success("Yetkili Verileri Hazırlandı");

                form.setFields([
                    {
                        name:['fullName'],
                        value:result.data.fullName
                    },
                    {
                        name:['phone'],
                        value:result.data.phone
                    },
                    {
                        name:['email'],
                        value:result.data.email
                    }
                ])

                setLoading(false);

            }else{
                message.error(result.message);
            }


        }).catch(error=>{
            message.error(error.message);
            setLoading(false);
        });


    }

    React.useEffect(()=>{

        if(props?.visible){
            getDataToApiServer();
        }


    },[props.id])

    return (
        <>

            <Drawer
                title="Yetkili Güncelleme"
                width={720}
                onClose={props.onClose}
                visible={props.visible}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <ConfirmApiRequest
                            buttonText={"Kaldır"}
                            server={"/v1/frontend/admin/delete"}
                            params={{
                                id: props.id
                            }}
                            result={()=>{
                                props?.result(true);
                                props?.onClose(false);
                            }}
                        />
                        <Button onClick={()=>{ form.submit() }} type="primary" loading={loading}>Güncelle</Button>
                    </Space>
                }>

                {
                    !loading &&
                    <Form  form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="fullName" label="İsim Soyisim" rules={[{required: true, message:"Lütfen İsim Soyisim Giriniz"}]}>
                                    <Input placeholder={"Veri Giriniz..."}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="email" label="E-Posta Adresi" rules={[{required: true, message:"Lütfen E-Posta Adresi Giriniz"}]}>
                                    <Input placeholder={"Veri Giriniz..."}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="phone" label="Telefon Numarası" rules={[{required: true, message:"Lütfen Telefon Numarası Giriniz"}]}>
                                    <Input placeholder={"Veri Giriniz..."}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="password" label="Yeni Şifre" rules={[{required: false, message:"Lütfen Yeni Şifre Giriniz"}]}>
                                    <Input.Password placeholder={"Veri Giriniz..."}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                }
            </Drawer>
        </>
    );
};


export default ViewAdmin;