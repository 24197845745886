
import React, { Component } from "react";
import {Link, useLocation} from "react-router-dom";
import { Layout, message, Button, Row, Col, Typography, Form, Input, Switch, } from "antd";
import signinbg from "../Assets/images/img-signin.jpg";
import sendApi from "../Configs/Api";


const SignIn = () =>  {

  const { Title }             = Typography;
  const {  Content }          = Layout;
  const locationChanger       = useLocation();
  const [loading,setLoading] = React.useState(false);

  React.useEffect(() => { }, [locationChanger]);
  const onChange = (checked) => { }

  const onFinish = (values) => {
    setLoading(true);
    sendApi("/v1/frontend/login",{
      username: values.email,
      password: values.password
    }).then(result =>{
      if(result.status === "success"){
        localStorage.setItem("tokenJWT",result.data.token);
        message.success("Giriş Yapıldı",1500);
        setTimeout(()=>{
          window.location.href = "/dashboard"
        },1500)
      }else{
        message.error(result.message);
        setLoading(false);
      }
    }).catch(err=>{
      message.error(err.message);
      setLoading(false);
    })
  };

  const onFinishFailed = (errorInfo) => message.error('This is an error message');

  return (
      <>
        <Layout className="bgWhite layout-default layout-signin">
          <Content className="signin">
            <Row gutter={[24, 0]} justify="space-around">
              <Col  xs={{ span: 24, offset: 0 }}  lg={{ span: 6, offset: 0 }}  md={{ span: 12 }}>
                <Title className="font-regular text-muted text-center" level={2} style={{marginBottom:50, marginTop:50}}> Umex Eğitim Paneli </Title>
                <Form  onFinish={onFinish}  onFinishFailed={onFinishFailed}  layout="vertical"  className="row-col">

                  <Form.Item
                      className="username"
                      label="E-Posta"
                      name="email"
                      rules={[ { required: true,  message: "Lütfen E-posta adresinizi giriniz!", } ]}>
                    <Input placeholder="E-Posta" />
                  </Form.Item>

                  <Form.Item
                      className="username"
                      label="Şifre"
                      name="password"
                      rules={[{required: true, message: "Lütfen şifrenizi giriniz!"  },]}>
                    <Input.Password placeholder="Şifre" />
                  </Form.Item>

                  <Form.Item  name="remember"  className="aligin-center"  valuePropName="checked">
                    <Switch defaultChecked onChange={onChange} />
                    Giriş Bilgilerimi Hatırla
                  </Form.Item>

                  <Form.Item>
                    <Button  type="primary" name={"submit"}  htmlType="submit" style={{ width: "100%" }} loading={loading}>
                      GİRİŞ YAP
                    </Button>
                  </Form.Item>

                </Form>
              </Col>

            </Row>
          </Content>
        </Layout>
      </>
  );

}

export default SignIn;