import {Breadcrumb, Tabs, Button, Col, Slider, Drawer, Form, Input, Row, Select, Result, Space, Upload, message, Progress, Tag, Divider, Table, Card, Image, } from 'antd';
import {CheckCircleOutlined, FieldTimeOutlined, UploadOutlined} from '@ant-design/icons';
import ReactWebMediaPlayer from 'react-web-media-player';

import React, { useState } from 'react';
import sendApi from "../../Configs/Api";
import {ContentServer} from "../../Configs/Settings";
import ConfirmApiRequest from "../../Configs/ConfirmApiRequest";

const ViewLesson = (props) => {

    const [form]                                = Form.useForm();
    const { TabPane }                           = Tabs;
    const [fileListVideo, setFileListVideo]     = useState([]);
    const [fileListCover, setFileListCover]     = useState([]);
    const [getSubscribers, setSubscribers]      = useState([]);
    const [getTabKey, setTabKey]                = useState(0);
    const [dataLoading, setDataLoading]         = useState(false);
    const [uploadLoading, setUploadLoading]     = useState(false);
    const [percentView, setPercentView]         = useState(0);
    const [getPercent, setPercent]              = useState(0);
    const [getLesson, setLesson]                = useState({});
    const { Option }                            = Select;

    const getDataToApiServer = () => {

        setDataLoading(true);
        setUploadLoading(true);
        setSubscribers([]);
        setFileListVideo([]);
        setFileListCover([]);

        sendApi("/v1/frontend/lessons/details",{ serial: props.serial })
            .then((result)=>{

            if(result.status === "success"){

                setLesson(result.data);

                form.setFields([
                    {
                        name:['title'],
                        value:result.data.title
                    },
                    {
                        name:['status'],
                        value:result.data.status
                    },
                    {
                        name:['percent'],
                        value:parseInt(result.data.percent)
                    }
                ]);

                setPercent(result.data.percent);

                setFileListVideo([{
                    uid: result?.data?.serial+"_video",
                    name: result?.data?.video,
                    status: 'done',
                    url: ContentServer+result?.data?.video,
                }]);

                setFileListCover([{
                    uid: result?.data?.serial+"_cover",
                    name: result?.data?.cover,
                    status: 'done',
                    url: ContentServer+result?.data?.cover,
                }]);

                setDataLoading(false);
                setUploadLoading(false);

            }else{
                message.error(result.message,.8);
            }

        })
            .catch((error)=>message.error(error.message,.8));

        sendApi("/v1/frontend/lessons/subscribers",{ serial: props.serial })
            .then((result) => {
                if(result.status === "success"){
                    result.data.subscribers.map(item=> setSubscribers(getSubscribers => [...getSubscribers,{
                        customer: item.customer.fullName,
                        video_percent: item.video.percent,
                        process_percent: item.process.percent,
                        status: item.process.status,
                        time: item.process.time
                    }]));

                }else{
                    message.error(result.message,.8);
                }
            })
            .catch((error)=>message.error(error.message,.8));

    }

    const onFinish = (values) => {

        if(values.title.length < 5){
            return message.error("Lütfen geçerli bir başlık giriniz")
        }

        setUploadLoading(true);
        setDataLoading(true);

        sendApi("/v1/frontend/lesson/update",{
            serial:             props.serial,
            education_category: props.category,
            title:              values.title === undefined ? "" : values.title,
            status:             values.status === undefined ? "" : values.status,
            percent:            values.percent === undefined ? "" : values.percent,
            video:              values.video === undefined ? "" : values.video.file,
            cover:              values.cover === undefined ? "" : values.cover.file
        },(income)=>{
            if(income > 0){
                setPercentView(income);
            }
            if(income >= 100){
                setPercentView(0);
                setUploadLoading(false)
            }
        }).then(result => {
            if(result.status === "success"){
                getDataToApiServer();
                message.success(result.message);
                props?.result(true);
            }else{
                message.error(result.message);
            }
            setUploadLoading(false);
            setDataLoading(false);
        }).catch(error=>message.error(error.message));

    };


    React.useEffect(()=>{
        return ()=>{
            form.resetFields();
            setUploadLoading(false);
            setDataLoading(false);
            setFileListVideo([]);
            setFileListCover([]);
            setSubscribers([]);
        };
    },[props?.serial]);

    const DescriptionItem = ({ title, content }) => <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>;

    React.useEffect(()=>{
        if(props?.visible){
            getDataToApiServer();
        }
    },[props?.visible]);

    const propsCover    = {
        accept:".jpg,.jpeg,.JPG,.JPEG",
        maxCount:1,
        onRemove: (file) => {
            const index = fileListCover.indexOf(file);
            const newFileList = fileListCover.slice();
            newFileList.splice(index, 1);
            setFileListCover(newFileList);
        },
        beforeUpload: (file) => {
            setFileListCover([...fileListCover,file]);
            return false;
        },
        onChange: ({ file, fileList })=>{
            setFileListCover(fileList);
        },
        fileList: fileListCover
    };
    const propsVideo    = {
        accept:".mp4,.MP4",
        maxCount:1,
        onRemove: (file) => {
            const index = fileListVideo.indexOf(file);
            const newFileList = fileListVideo.slice();
            newFileList.splice(index, 1);
            setFileListVideo(newFileList);
        },
        beforeUpload: (file) => {
            setFileListVideo([...fileListVideo,file]);
            return false;
        },
        onChange: ({ file, fileList })=>{
            setFileListVideo(fileList);
        },
        fileList: fileListVideo
    };
    const columns       = [
        {
            title: 'Müşteri',
            dataIndex: 'customer',
            key: 'customer'
        },
        {
            title: 'Video Yüzdesi',
            dataIndex: 'video_percent',
            key: 'video_percent',
            render: (_, record) => (
                <>
                    <Progress
                        width={50}
                        type="circle"
                        strokeColor={{
                            '100%': '#87d068',
                        }}
                        percent={parseInt(record.video_percent)}/>

                </>
            )
        },
        {
            title: 'İzleme Oranı',
            dataIndex: 'process_percent',
            key: 'process_percent',
            render: (_, record) => (
                <>
                    <Progress
                        width={50}
                        type="circle"
                        strokeColor={{
                            '0%': '#eb6b24',
                            '100%': '#87d068',
                        }}
                        percent={parseInt(record.process_percent)}/>

                </>
            )
        },
        {
            title: 'Eğitim Durumu',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                <>
                    {record.status ? (<Tag color={"green"}>TAMAMLADI</Tag>) : (<Tag color={"volcano"}>DEVAM EDİYOR</Tag>)}
                </>
            ),
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'time',
            key: 'time'
        }
    ];

    return (
        <>
            <Drawer  title={<Breadcrumb>
                    <Breadcrumb.Item>Eğitimler</Breadcrumb.Item>
                    <Breadcrumb.Item>{getLesson?.category?.title}</Breadcrumb.Item>
                    <Breadcrumb.Item>{getLesson?.title}</Breadcrumb.Item>
                </Breadcrumb>}
                width={"80%"}
                onClose={props.onClose}
                visible={props.visible}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <>
                        {
                            parseInt(getTabKey) === 2 &&
                            <Space>
                                <Button onClick={()=>{ form.submit() }} type="primary">Güncelle</Button>
                            </Space>
                        }
                        {
                            parseInt(getTabKey) === 1 &&
                            <Space>
                                <ConfirmApiRequest
                                    buttonText={"Eğitimi Kaldır"}
                                    server={"/v1/frontend/lessons/delete"}
                                    params={{
                                        serial: props.serial
                                    }}
                                    result={()=>{
                                        props?.result(true);
                                        props?.onClose(false);
                                    }}
                                />
                            </Space>
                        }
                    </>
                }>
                <>

                    <Tabs defaultActiveKey="1" onChange={keyOfTab=>setTabKey(keyOfTab)}>
                        <TabPane tab="Eğitim Bilgileri" key="1">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    display: 'flex',
                                }}>
                                <Card title="Eğitim Detayları" size="small">
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="Başlık" content={getLesson?.title} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="Eğitim Kodu" content={getLesson?.serial} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="Oluşturan" content={getLesson?.admin?.fullName} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="Oluşturma Tarihi" content={getLesson?.time} />
                                        </Col>
                                    </Row>
                                </Card>
                                {
                                    (getLesson?.cover && getLesson?.video) &&
                                    <Card title="Eğitim İçerik" size="small">
                                        <Row>
                                            <Col span={12} style={{display:"flex", borderRadius:5, overflow:'hidden', alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                               <Col style={{display:"flex",width:440, height:250, borderRadius:5, overflow:'hidden',alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                                   <Image
                                                       style={{borderRadius:5,  overflow:'hidden'}}
                                                       height={"100%"}
                                                       src={ContentServer+getLesson?.cover}
                                                   />
                                               </Col>
                                            </Col>
                                            <Col span={12} style={{display:"flex", borderRadius:5, overflow:'hidden', alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                                <Col style={{width:440, height:250, borderRadius:5, overflow:'hidden'}}>
                                                    <ReactWebMediaPlayer
                                                        title={getLesson?.title}
                                                        video={ContentServer+getLesson?.video}
                                                        thumbnail={ContentServer+getLesson?.cover}
                                                        width={"100%"}
                                                        height={"100%"}
                                                    />
                                                </Col>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12} >
                                                <Divider>Video Tanıtım Görseli</Divider>
                                            </Col>
                                            <Col span={12} >
                                                <Divider>Eğitim Videosu</Divider>
                                            </Col>
                                        </Row>
                                    </Card>
                                }
                                <Card title="Kategori Detayları" size="small">
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="Başlık" content={getLesson?.category?.title} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="Kategori Kodu" content={getLesson?.category?.serial} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="Oluşturan" content={getLesson?.category?.admin?.fullName} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="Oluşturma Tarihi" content={getLesson?.category?.time} />
                                        </Col>
                                    </Row>
                                </Card>
                                <Card title="Sertifika İçeriği" size="small">
                                    <Row>
                                        <Col span={24}>
                                            <DescriptionItem title="Sertifika Yazı 1" content={getLesson?.category?.certificate_1} />
                                        </Col>
                                        <Col span={24}>
                                            <DescriptionItem title="Sertifika Yazı 2" content={getLesson?.category?.certificate_2} />
                                        </Col>
                                        <Col span={24}>
                                            <DescriptionItem title="Sertifika Yazı 3" content={getLesson?.category?.certificate_3} />
                                        </Col>
                                    </Row>
                                </Card>
                                {
                                    getLesson?.category &&
                                    <Card title="Kategori Görsel İçerik" size="small">
                                        <Row>
                                            <Col span={12} style={{display:"flex", borderRadius:5, overflow:'hidden', alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                                <Col style={{display:"flex",width:440, height:250, borderRadius:5, overflow:'hidden',alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                                    <Image
                                                        style={{borderRadius:5}}
                                                        height={"100%"}
                                                        src={ContentServer+getLesson?.category?.logo}
                                                    />
                                                </Col>
                                            </Col>
                                            <Col span={12} style={{display:"flex", borderRadius:5, overflow:'hidden', alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                                <Col style={{display:"flex",width:440, height:250, borderRadius:5, overflow:'hidden',alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                                    <Image
                                                        style={{borderRadius:5}}
                                                        height={"100%"}
                                                        src={ContentServer+getLesson?.category?.cover}
                                                    />
                                                </Col>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={12} >
                                                <Divider>Marka Logosu</Divider>
                                            </Col>
                                            <Col span={12} >
                                                <Divider>Detay Görseli</Divider>
                                            </Col>
                                        </Row>
                                    </Card>
                                }
                            </Space>
                        </TabPane>
                        <TabPane tab="Ayarlar" key="2">

                            { uploadLoading &&
                                <Result
                                    icon={<Progress
                                        type="circle"
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={percentView}/>}
                                    title="Eğitim Yükleniyor, Lütfen Bekleyiniz."
                                />
                            }

                            {
                                !uploadLoading &&
                                <Form  form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                                    <Row gutter={16}>
                                        <Col span={15}>
                                            <Form.Item name="title" label="Başlık" rules={[{required: true, message:"Lütfen bir başlık giriniz"}]}>
                                                <Input placeholder={"Lütfen bir başlık giriniz"} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item name="status" label="Yayın Durumu" rules={[{required: true, message:"Lütfen yayın durumu seçiniz"}]}>
                                                <Select placeholder="Seçim yapınız"  allowClear>
                                                    <Option value="active">Aktif</Option>
                                                    <Option value="passive">Pasif</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item name="percent" label={"İzleme Yüzdesi : %"+getPercent } rules={[{required: true, message:"Lütfen izleme yüzdesi seçiniz" }]}>
                                                <Slider defaultValue={0} disabled={false} onChange={(e) => setPercent(e)}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4} style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                            <Progress
                                                width={50}
                                                type="circle"
                                                strokeColor={{'0%': '#108ee9'}}
                                                percent={getPercent}/>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item name="video"  label="Video" rules={[{required: false, message:"Lütfen eğitim videosu seçiniz" }]}>
                                                <Upload {...propsVideo}>
                                                    <Button icon={<UploadOutlined />}>Video Seçiniz</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="cover"  label="Kapak"  rules={[{required: false, message:"Lütfen kapak görseli seçiniz"}]}>
                                                <Upload {...propsCover}>
                                                    <Button icon={<UploadOutlined />}>Kapak Seçiniz</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        </TabPane>
                        <TabPane tab="Müşteriler" key="3">
                            <Table columns={columns} dataSource={getSubscribers} />
                        </TabPane>
                    </Tabs>
                    </>
            </Drawer>
        </>
    );
};


export default ViewLesson;