import {
    Button,
    Col, DatePicker,
    Descriptions,
    Dropdown,
    Form,
    Input,
    Layout,
    Menu, message,
    PageHeader, Popover,
    Row,
    Select,
    Table,
    Tabs, Upload
} from 'antd';
import React from 'react';
import sendApi from "../Configs/Api";
import { CheckCircleTwoTone, HeartTwoTone, SmileTwoTone } from '@ant-design/icons';

import data from '@emoji-mart/data'
import { Picker } from 'emoji-mart'


function PushNotificationSend() {

    const { Content }                               = Layout;
    const { TabPane }                               = Tabs;
    const { Option }                                = Select;
    const { TextArea }                              = Input;
    const [form]                                    = Form.useForm();
    const [getCustomers,setCustomers]               = React.useState([]);
    const [getType,setType]                         = React.useState(false);
    const [getLoading,setLoading]                   = React.useState(false);

    const [drawX,setDrawX] = React.useState(false);

    const onChangeStatus = (value) => {

        setType(value === "sms");

    };


    const EmojiPicker =  (props) => {
        const ref = React.useRef()

        React.useEffect(() => {
            new Picker({ ...props, data, ref })
        }, [])

        return <div ref={ref} />
    }

    React.useEffect(()=>{
        sendApi("/v1/frontend/customer/list")
            .then((result)=>{

                if(result.status === "success"){

                    setCustomers([]);
                    result.data.map(item=>setCustomers(getCustomers => [...getCustomers,{
                        serial: item.serial,
                        fullName: item.fullName
                    }]));

                }else{
                    message.error(result.message);
                }

            }).catch((error)=>{
            message.error(error.message);
        });
    },[])
    
    const onFinish = (result) => {

        setLoading(true);

        sendApi("/v1/frontend/customer/notification",{
            title:result.title,
            content:result.content,
            type:result.type,
            target:result.target
        }).then((result)=>{
                if(result.status === "success"){
                    message.success("Bildirim Gönderildi");
                    form.resetFields();
                    setType(false);
                }else{
                    message.error(result.message);
                }
                setLoading(false);
            }).catch((error)=>{
            message.error(error.message);
        });

    }

    return (
        <>

            <Content className="site-layout" style={{ padding: '0 10px', marginTop: 10 }}>
                <PageHeader
                    className="site-page-header-responsive"
                    onBack={() => window.history.back()}
                    title="Bildirim Gönderme Merkezi"
                    subTitle="Bu alandan müşterilere bildirim gönderebilirsiniz.">
                    <div className="content">
                        <div className="main">
                                <Row gutter={24}  >
                                    <Col span={14}>
                                        <Form  form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                                            <Row gutter={24} style={{paddingTop:20, paddingBottom:20}}>
                                                <Col span={24}>
                                                    <Form.Item name="type" label="Gönderim Tipi" rules={[{required: true, message:"Lütfen gönderim tipi seçiniz"}]}>
                                                        <Select showArrow showSearch     filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}  placeholder="Seçim yapınız" onChange={onChangeStatus} allowClear>
                                                            <Option key={"sms"} value="sms">Sms Gönderimi</Option>
                                                            <Option key={"notification"} value="notification">Bildirim Gönderimi</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="target" label="Hedef Kitle" rules={[{required: true, message:"Lütfen hedef kitle seçiniz"}]}>
                                                        <Select showArrow showSearch     filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}  placeholder="Seçim yapınız"  allowClear>
                                                            <Option key={"all"} value="all">Tüm Müşteriler</Option>
                                                            {getCustomers.map(item => <Option key={item.serial} value={item.serial}>{item.fullName}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="title" label="Başlık" rules={[{required: !getType, message:"bildirim Başlığı Giriniz..."}]}>
                                                        <Input placeholder={"Başlık..."}  disabled={getType} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24} style={{position:"relative"}} >
                                                    <Form.Item name="content" label="İçerik" rules={[{required: true, message:"Bildirim içeriği giriniz..."}]}>
                                                        <TextArea rows={10} placeholder="Bildirim İçeriği..." />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Button loading={getLoading} style={{width:"100%"}} color={"primary"} onClick={()=>{ form.submit(); }}>GÖNDER</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                    <Col span={10} style={{paddingTop:47, display:"flex", justifyContent:"center"}}>
                                        {!getType && <EmojiPicker
                                            theme={"light"}
                                            onEmojiSelect={(getEmoji)=>{
                                                navigator.clipboard.writeText(getEmoji.native);
                                                message.success(getEmoji.native+" Kopyalandı, Şimdi kullanabilirsiniz")
                                            }}
                                        />}
                                    </Col>
                                </Row>



                        </div>
                    </div>
                </PageHeader>
            </Content>

        </>
    );
}

export default PushNotificationSend;
