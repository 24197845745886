import { Button, Result } from 'antd';

const NotFound = (props) => (
    <Result
        status="404"
        title="404"
        subTitle="Üzgünüz, aradığınız sayfayı bulamadık."
        extra={<Button onClick={()=> window.location.href = props.to } type="primary">{props.buttonTitle}</Button>}
    />
);

export default NotFound;