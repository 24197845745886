import React,{ useState,useRef } from "react";
import {message, Button, Layout, PageHeader,Input, Descriptions, Tabs, Tag, Space, Table, Result, Spin} from "antd";
import {RightOutlined, SearchOutlined} from "@ant-design/icons";
import sendApi from "../Configs/Api";
import ViewCustomer from "../Components/CustomerManagement/ViewCustomer";
import Highlighter from 'react-highlight-words';


function CustomerManagement() {

    const { Content } = Layout;

    const [getCustomers,setCustomers]                   = React.useState([]);
    const [getCustomer,setCustomer]                     = React.useState(null);
    const [getCustomerVisible,setVisibleCustomer]       = React.useState(null);
    const [loading,setLoading]                          = React.useState(true);

    const [searchText, setSearchText]                   = useState('');
    const [searchedColumn, setSearchedColumn]           = useState('');
    const searchInput                                   = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 8}}>
                <Input ref={searchInput} placeholder={`Aranacak Bir İçerik Giriniz...`} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}  style={{marginBottom: 8, display: 'block'}}/>
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{width: 90}}>
                        Bul
                    </Button>
                    <Button onClick={() => {
                        clearFilters();
                        handleSearch(selectedKeys, confirm, dataIndex);
                        setSearchText('');
                    }} size="small" style={{width: 90}}>Temizle</Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0}} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text)
    });


    const getCustomersApiServe = () => {

        sendApi("/v1/frontend/customer/list")
            .then((result)=>{

                if(result.status === "success"){

                    setCustomers([]);

                    message.success("Müşteriler Yüklendi");

                    result.data.map(item=>setCustomers(getCustomers => [...getCustomers,{
                        serial: item.serial,
                        fullName: item.fullName,
                        status: item.status,
                        time: item.time
                    }]));

                    setLoading(false);

                }else{
                    message.error(result.message);
                }

            }).catch((error)=>{
            message.error(error.message);
        });

    }

    React.useEffect(()=>{

        getCustomersApiServe();

    },[]);


    const customerColumns = [
        {
            title: 'İsim Soyisim',
            dataIndex: 'fullName',
            key: 'fullName',
            ...getColumnSearchProps('fullName')
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                    text: 'Aktif Kayıtlar',
                    value: 'active',
                },
                {
                    text: 'Pasif Kayıtlar',
                    value: 'passive',
                },
            ],
            render: (_, record) => <Tag color={record.status === "active" ? "green" : "red"} >{record.status === "active" ? "AKTİF" : "PASİF"}</Tag>,

        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'time',
            key: 'time',
            ellipsis: true,
            sorter: (a, b) => a.fullName.length - b.fullName.length
        },
        {
            title: 'İşlem',
            key: 'serial',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="primary"  icon={<RightOutlined />} onClick={()=>{

                        setCustomer(record.serial);
                        setVisibleCustomer(true);

                    }}>Detay Git</Button>
                </Space>
            ),
        },
    ];

    return (
    <>
        <Content className="site-layout" style={{ padding: '0 10px', marginTop: 10 }}>
            <PageHeader
                className="site-page-header-responsive"
                onBack={() => window.history.back()}
                title="Müşteri Yönetimi"
                subTitle="Bu alanda müşterilerinizi yönetebilirsiniz."
                extra={[]}
                footer=
                {<>
                    { loading &&  <Result icon={<Spin size={"large"}/>}  title="Müşteriler Yükleniyor, Lütfen Bekleyiniz." />}
                    {!loading && <Table columns={customerColumns} dataSource={getCustomers} /> }
                </>}>
                <div className="content">
                    <div className="main">
                        <Descriptions size="small" column={3}>
                            <Descriptions.Item label="Toplam Müşteri">{getCustomers.length}</Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>
            </PageHeader>
        </Content>

        <ViewCustomer
            visible={getCustomerVisible}
            serial={getCustomer}
            onClose={()=>{
                setVisibleCustomer(false)
            }}
            onResult={()=>{
                getCustomersApiServe();
            }}
        />
    </>
  );
}

export default CustomerManagement;
