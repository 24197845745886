import sendApi from "./Api";
import {Button, message, Popconfirm} from "antd";

const ConfirmApiRequest = (props) => {

    return (
        <Popconfirm
            placement="bottomRight"
            title={props?.title ? props?.title : "İşlemi Onaylıyor Musunuz?"}
            onConfirm={()=>{
                sendApi(props.server,props.params)
                    .then(result => {
                        if(result.status === "success"){
                            props.result(true);
                            message.success("İşlem Başarılı");
                        }else{
                            message.error(result.message);
                            props.result(false);
                        }
                    }).then(error=>{
                        message.error(error.message);
                        props.result(false);
                    })
            }}
            onCancel={()=>{}}
            okText="Evet"
            cancelText="Hayır">
            {props?.content ? <props.content /> : <Button type={props?.type ? props?.type : "danger"}>{props?.buttonText ? props?.buttonText : "Kaydı Sil"}</Button> }
        </Popconfirm>
    )
}

export  default ConfirmApiRequest;