import axios from 'axios';
const FormData = require('form-data');

const sendApi = (actions,data,callback = (returnData)=>{}) => {

    let formDataListener = new FormData();
    const {ApiServer}  = require('./Settings');


    for(let key in data){
        formDataListener.append(key, data[key])
    }

    let letAutoHeaderAppend = {};

    if(localStorage.getItem("tokenJWT") !== null){
        letAutoHeaderAppend =
            {
                headers:  {
                        'Bearer' : localStorage.getItem("tokenJWT")
                    }
            };
    }

    let options = {
        onUploadProgress: (p) => {
            return callback(((p.loaded / p.total) * 100).toFixed(0))
        },
        ...letAutoHeaderAppend
    };

    return axios.postForm(ApiServer + actions,formDataListener, options).then(response=>response.data)

}


export default sendApi;