import { AppstoreAddOutlined,FireOutlined,LogoutOutlined, HomeOutlined, DatabaseOutlined, UserOutlined, FileImageOutlined, NotificationOutlined, SettingOutlined } from '@ant-design/icons';

import Home from "../Pages/Home";
import EducationCategories from "../Pages/EducationCategories";
import CustomerManagement from "../Pages/CustomerManagement";
import CardManagement from "../Pages/CardManagement";
import {Modal, message} from "antd";
import PushNotificationSend from "../Pages/PushNotificationSend";
import AppManagement from "../Pages/AppManagement";
import AdminManagement from "../Pages/AdminManagement";

export const RouterConfig = [
    {
        label: "SİSTEM MENÜSÜ",
        children:  [
            {
                label: "Anasayfa",
                icon: <HomeOutlined />,
                path: "/dashboard",
                component: Home,
                menu: true,
                route: true,
                exact:true,
                process: false,
                onClick:()=>{}
            },
            {
                label: "Eğitim Yönetimi",
                icon: <DatabaseOutlined />,
                path: "/educations",
                component: EducationCategories,
                menu: true,
                route: true,
                exact:false,
                process: false,
                onClick:()=>{}
            },
            {
                label: "Müşteriler",
                icon: <UserOutlined />,
                path: "/customers",
                component: CustomerManagement,
                menu: true,
                route: true,
                exact:false,
                process: false,
                onClick:()=>{}
            },
            {
                label: "Kart Yönetimi",
                icon: <FileImageOutlined />,
                path: "/mobile/cards",
                component: CardManagement,
                menu: true,
                route: true,
                exact:false,
                process: false,
                onClick:()=>{}
            },
            {
                label: "Bildirim Gönder",
                icon: <NotificationOutlined />,
                path: "/notification",
                component: PushNotificationSend,
                menu: true,
                route: true,
                exact:false,
                process: false,
                onClick:()=>{}
            },
            {
                label: "Uygulama Yönetimi",
                icon: <AppstoreAddOutlined />,
                path: "/apps",
                component: AppManagement,
                menu: true,
                route: true,
                exact:false,
                process: false,
                onClick:()=>{}
            },
            {
                label: "Yetkili İşlemleri",
                icon: <FireOutlined />,
                path: "/settings",
                component: AdminManagement,
                menu: true,
                route: true,
                exact:false,
                process: false,
                onClick:()=>{}
            },
            {
                label: "Oturum Kapat",
                icon: <LogoutOutlined />,
                path: "#",
                component: Home,
                menu: false,
                route: false,
                exact:false,
                process: true,
                onClick:()=>{
                    const { confirm } = Modal;

                    confirm({
                        title: 'İşlem Onayı ?',
                        icon: <LogoutOutlined />,
                        content: 'Oturum Kapatmak İstiyor Musunuz?',
                        okText: 'Oturum Kapat',
                        cancelText: 'İptal Et',
                        onOk() {
                            message.success("Oturum Kapatıldı");
                            localStorage.removeItem("tokenJWT")
                        },

                        onCancel() {},
                    });
                }
            }
        ]
    }
];