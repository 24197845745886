import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Col, Slider, Drawer, Form, Input, Row, Select, Result, Space, Upload, message, Progress} from 'antd';
import React, { useState } from 'react';
import sendApi from "../../Configs/Api";

const CreateNewLesson = (props) => {

    const [form]                                = Form.useForm();
    const { Option }                            = Select;
    const [fileListVideo, setFileListVideo]     = useState([]);
    const [fileListCover, setFileListCover]     = useState([]);
    const [loading, setLoading]                 = useState(false);
    const [percentView, setPercentView]         = useState(0);


    React.useEffect(()=>{
        return ()=>{
            form.resetFields();
        };
    },[props]);

    const onFinish = (values) => {

        setLoading(true);

        sendApi("/v1/frontend/lesson/create",{
            education_category: props.serial,
            title: values.title === undefined ? "" : values.title,
            status: values.status === undefined ? "" : values.status,
            percent: values.percent === undefined ? "" : values.percent,
            cover: values.cover === undefined ? "" : values.cover.file,
            video: values.video === undefined ? "" : values.video.file,
        },(income)=>{
            if(income > 0){
                setPercentView(income);
            }
            if(income >= 100){
                setPercentView(0);
            }
        }).then(result=> {
            setLoading(false);
            if(result.status === "success"){
                message.success("Eğitim Yüklendi",.3);
                props?.result(true,result.data.serial);
                props?.onClose(true);
                form?.resetFields();
            }else{
                message.error(result.message,.8);
                props?.result(false,null);
            }
        }).catch(error=>{
            setLoading(false);
            message.error(error.message,.8);
            console.log(error)
            props?.result(true,null);
        });
    };

    const propsVideo = {
        maxCount:1,
        onRemove: (file) => {
            const index = fileListVideo.indexOf(file);
            const newFileList = fileListVideo.slice();
            newFileList.splice(index, 1);
            setFileListVideo(newFileList);
        },
        beforeUpload: (file) => {
            setFileListVideo([...fileListVideo, file]);
            return false;
        },
        fileListVideo,
    };
    const propsCover = {
        maxCount:1,
        onRemove: (file) => {
            const index = fileListCover.indexOf(file);
            const newFileList = fileListCover.slice();
            newFileList.splice(index, 1);
            setFileListCover(newFileList);
        },
        beforeUpload: (file) => {
            setFileListCover([...fileListCover, file]);
            return false;
        },
        fileListCover,
    };

    return (
        <>

            <Drawer
                title="Yeni Eğitim Oluşturma"
                width={720}
                onClose={props.onClose}
                visible={props.visible}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        {!loading && <Button type={"danger"} onClick={()=>{ form.resetFields() }}>Formu Sıfırla</Button> }
                        <Button onClick={()=>{ form.submit() }} type="primary" loading={loading}>Oluştur</Button>
                    </Space>
                }>
                <>

                    { loading &&  <Result icon={<Progress  type="circle"  strokeColor={{ '0%': '#108ee9',  '100%': '#87d068'}}  percent={percentView}/>}  title="Eğitim Yükleniyor, Lütfen Bekleyiniz." />}

                        { !loading && <Form  form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                                <Row gutter={16}>
                                    <Col span={15}>
                                        <Form.Item name="title" label="Başlık" rules={[{required: true, message:"Lütfen bir başlık giriniz"}]}>
                                            <Input placeholder={"Lütfen bir başlık giriniz"}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item name="status" label="Yayın Durumu" rules={[{required: true, message:"Lütfen yayın durumu seçiniz"}]}>
                                            <Select placeholder="Seçim yapınız"  allowClear>
                                                <Option value="active">Aktif</Option>
                                                <Option value="passive">Pasif</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="percent" label="İzleme Yüzdesi" rules={[{required: true, message:"Lütfen izleme yüzdesi seçiniz" }]}>
                                            <Slider defaultValue={0} disabled={false} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item name="video" label="Video" rules={[{required: true, message:"Lütfen eğitim videosu seçiniz" }]}>
                                            <Upload {...propsVideo}>
                                                <Button icon={<UploadOutlined />}>Video Seçiniz</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="cover" label="Kapak Görseli" rules={[{required: true, message:"Lütfen kapak görseli seçiniz"}]}>
                                            <Upload {...propsCover}>
                                                <Button icon={<UploadOutlined />}>Kapak Seçiniz</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </>
            </Drawer>
        </>
    );
};


export default CreateNewLesson;