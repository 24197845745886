import {Breadcrumb, Tabs, Button, Col, Modal, Drawer, Form,Alert,Input, Row, Select, Space, DatePicker, message, Progress, Tag, Divider, Table, Card, Image, } from 'antd';
import React, { useState } from 'react';
import sendApi from "../../Configs/Api";
import moment from 'moment';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

const ViewCustomer = (props) => {

    const [form]                                                = Form.useForm();
    const { TabPane }                                           = Tabs;
    const { Option }                                            = Select;
    const [getSubscribers, setSubscribers]                      = useState([]);
    const [getTabKey, setTabKey]                                = useState(0);
    const [dataLoading, setDataLoading]                         = useState(false);
    const [getCustomer, setCustomer]                            = useState({});
    const [getSubLesson,setSubLesson]                           = useState([]);
    const [getSubLessonInitialize,setSubLessonInitialize]       = useState([]);
    const [isModalVisible, setIsModalVisible]                   = useState(false);
    const [isModalTitle, setIsModalTitle]                       = useState("");
    
    const getDataToApiServer = () => {

        setDataLoading(true);
        setSubscribers([]);

        sendApi("/v1/frontend/customer/subscribers",{ serial: props.serial })
            .then((result)=>{

                const getData = [];
                (result.data).map((item) => {
                    getData.push({
                        key: Object.values(item)[0].category.serial,
                        serial: Object.values(item)[0].category.serial,
                        title: Object.values(item)[0].category.title,
                        lessons: Object.values(item)[0].lessons,
                        completed: Object.values(item)[0].completed_education,
                        status: Object.values(item)[0].completed_education
                    });

                });

                setSubscribers(getData);
                setDataLoading(false);

            })
            .catch((error)=>{});


        sendApi("/v1/frontend/customer/details",{ serial: props.serial })
            .then((result)=>{

            if(result.status === "success"){

                setCustomer(result.data);

                form.setFields([
                    {
                        name:['fullName'],
                        value:result.data.fullName
                    },
                    {
                        name:['phone'],
                        value:result.data.phone
                    },
                    {
                        name:['status'],
                        value:result.data.status
                    },
                    {
                        name:['birthday'],
                        value: moment(result.data.birthday,"DD/MM/YYYY")
                    },
                    {
                        name:['company_title'],
                        value: result.data.company_title === null ? "" : result.data.company_title
                    },
                    {
                        name:['company_address'],
                        value: result.data.company_address === null ? "" : result.data.company_address
                    }
                ]);

            }else{
                message.error(result.message,.8);
            }

        })
            .catch((error)=>message.error(error.message,.8));

    }

    React.useEffect(()=>{
        return ()=>{
            form.resetFields();
            setDataLoading(false);
            setSubscribers([]);
        };
    },[props?.serial]);

    React.useEffect(()=>{
        if(props?.visible){
            getDataToApiServer();
        }
    },[props?.visible]);

    React.useEffect(()=>{
        setSubLessonInitialize([]);
        Object.values(getSubLesson).map(({video,process})=>{
            setSubLessonInitialize(getSubLessonInitialize => [...getSubLessonInitialize,{
                title: video.title,
                video_percent: video.percent,
                process_percent: process ? process.watching_percent : 0,
                status: process ? process.status : false,
                time: process ? process.date : false
            }])
        });
    },[getSubLesson]);

    const DescriptionItem = ({ title, content }) =>
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>;


    const lessonColumns       =  [
        {
            title: 'Eğitim Adı',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Video Yüzdesi',
            dataIndex: 'video_percent',
            key: 'video_percent',
            render: (_, record) => (
                <>
                    <Progress
                        width={50}
                        type="circle"
                        strokeColor={{
                            '100%': '#87d068',
                        }}
                        percent={parseInt(record.video_percent)}/>

                </>
            )
        },
        {
            title: 'İzleme Oranı',
            dataIndex: 'process_percent',
            key: 'process_percent',
            render: (_, record) => (
                <>
                    <Progress
                        width={50}
                        type="circle"
                        strokeColor={{
                            '0%': '#eb6b24',
                            '100%': '#87d068',
                        }}
                        percent={parseInt(record.process_percent)}/>

                </>
            )
        },
        {
            title: 'Eğitim Durumu',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                <>
                    {record.status ? (<Tag color={"green"}>TAMAMLADI</Tag>) : (<Tag color={"warning"}>BEKLEMEDE</Tag>)}
                </>
            ),
        },
        {
            title: 'Tarih',
            dataIndex: 'time',
            key: 'time',
            render: (_, record) => (
                <>
                    {record.time ? record.time : (<Tag color={"volcano"}>BAŞLAMADI</Tag>)}
                </>
            ),
        }
    ];


    const categoryColumns       = [
        {
            title: 'Eğitim Adı',
            dataIndex: "title",
            key: "title"
        },
        {
            title: 'Eğitim Durumu',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                <>
                    {record.status ? (<Tag color={"green"}>TAMAMLADI</Tag>) : (<Tag color={"volcano"}>DEVAM EDİYOR</Tag>)}
                </>
            ),
        },
        {
            title: 'İşlemler',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                <>
                   <Button color={"primary"} onClick={()=>{
                       setIsModalTitle(record.title);
                       setSubLesson(record.lessons);
                       setIsModalVisible(true);
                   }}>Eğitim İçerikleri</Button>
                </>
            ),
        }
    ];


    const onFinish = (result) => {

        if(result.fullName.length < 5){
            return message.error("Lütfen geçerli (İsim Soyisim) giriniz")
        }

        if(result.phone.length < 5){
            return message.error("Lütfen geçerli (Telefon Numarası) giriniz")
        }

        if(result.birthday.length < 5){
            return message.error("Lütfen geçerli (Doğum Tarihi) giriniz")
        }

        setDataLoading(true);

        sendApi("/v1/frontend/customer/update",{
            serial:              props.serial,
            fullName:            result.fullName,
            status:              result.status,
            phone:               result.phone,
            birthday:            result.birthday.format("DD/MM/YYYY"),
            company_title:       result.company_title,
            company_address:     result.company_address
        }).then(result => {
            if(result.status === "success"){
                getDataToApiServer();
                message.success(result.message);
                props?.onResult(true);
            }else{
                message.error(result.message);
            }
        }).catch(error=>message.error(error.message));


    }

    return (
        <>
            <Drawer  title={
                <Breadcrumb>
                    <Breadcrumb.Item>Müşteri Yönetimi</Breadcrumb.Item>
                    <Breadcrumb.Item>{getCustomer?.fullName}</Breadcrumb.Item>
                </Breadcrumb>}
                width={"80%"}
                onClose={props.onClose}
                visible={props.visible}
                bodyStyle={{ paddingBottom: 80}}
                extra={
                    <>
                        {
                            parseInt(getTabKey) === 3 &&
                            <Space>
                                <Button onClick={()=>{ form.submit() }} type="primary" loading={dataLoading}>Güncelle</Button>
                            </Space>
                        }
                    </>
                }>
                <>
                    <Tabs defaultActiveKey="1" onChange={keyOfTab=>setTabKey(keyOfTab)}>
                        <TabPane tab="Genel Bilgiler" key="1">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    display: 'flex',
                                }}>
                                {getCustomer?.status === "active" && <Alert  message="Aktif Müşteri"  type="success"  showIcon />}
                                {getCustomer?.status === "passive" && <Alert  message="Pasif Müşteri"  type="error"  showIcon />}
                                <Card title="Bireysel Bilgileri" size="small">
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="Kayıt No" content={getCustomer?.serial} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="İsim Soyisim" content={getCustomer?.fullName} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="Telefon Numarası" content={getCustomer?.phone} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="Doğum Tarihi" content={getCustomer?.birthday} />
                                        </Col>
                                    </Row>
                                </Card>
                                <Card title="Kurumsal Bilgileri" size="small">
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="Firma Adı" content={getCustomer?.company_title?.length > 3 ? getCustomer?.company_title : <Tag color={"red"} >Bulunamadı</Tag>} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="Firma Adresi" content={getCustomer?.company_address?.length > 3 ? getCustomer?.company_address : <Tag color={"red"} >Bulunamadı</Tag>} />
                                        </Col>
                                    </Row>
                                </Card>

                            </Space>
                        </TabPane>
                        <TabPane tab="Eğitimler" key="2">
                            <Table columns={categoryColumns}  dataSource={getSubscribers} />
                        </TabPane>
                        <TabPane tab="Ayarlar" key="3">
                            <Form  form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                                <Row gutter={16}>
                                    <Col span={15}>
                                        <Form.Item name="fullName" label="İsim Soyisim" rules={[{required: true, message:"İsim Soyisim Giriniz"}]}>
                                            <Input placeholder={"İsim Soyisim Giriniz"}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item name="status" label="Durumu" rules={[{required: true, message:"Lütfen durum seçiniz"}]}>
                                            <Select placeholder="Seçim yapınız"  allowClear>
                                                <Option value="active">Aktif</Option>
                                                <Option value="passive">Pasif</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="phone" label="Telefon Numarası" rules={[{required: true, message:"Lütfen sertifika alan 1 içeriği giriniz" }]}>
                                            <Input inputMode={"tel"}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="birthday" label="Doğum Tarihi" rules={[{required: false, message:"Lütfen sertifika alan 2 içeriği giriniz" }]}>
                                            <DatePicker
                                                disabledDate={(current) => {
                                                    let customDate = moment().format("YYYY-MM-DD");
                                                    return current && current > moment(customDate, "YYYY-MM-DD");
                                                }}
                                                locale={locale}
                                                style={{width:"100%"}}
                                                onChange={()=>{}}
                                                format={"DD/MM/YYYY"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="company_title" label="Firma Adı" rules={[{required: false, message:"Lütfen sertifika alan 3 içeriği giriniz" }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="company_address" label="Firma Adresi" rules={[{required: false, message:"Lütfen sertifika alan 3 içeriği giriniz" }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </TabPane>
                    </Tabs>

                    <Modal
                        footer={[
                            <Button color={"primary"} onClick={()=>{
                                setIsModalVisible(false);
                            }}>
                                Kapat
                            </Button>]}
                        width={800}
                        title={isModalTitle}
                        visible={isModalVisible}
                        onCancel={()=>{
                            setIsModalVisible(false);
                            setSubLessonInitialize([]);
                            setSubLesson([]);
                        }}
                    >
                        <Table columns={lessonColumns} dataSource={getSubLessonInitialize} pagination={false} />
                    </Modal>

                    </>
            </Drawer>
        </>
    );
};


export default ViewCustomer;