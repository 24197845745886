import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../Assets/images/umexLogo.svg";
import {RouterConfig} from "../../Configs/Router";

function Sidenav({ color }) {

  const { pathname } = useLocation();

  return (
    <>
      <div className="brand text-center"> <img src={logo} alt="" /> </div>
      <hr />
      <Menu theme="light" mode="inline">
        {
          RouterConfig.map(item => (
              <>
                <Menu.Item className="menu-item-header" key={Math.random()}> {item.label} </Menu.Item>
                {
                  item.children.map(child => {

                      if(child.menu){
                          return (
                              <Menu.Item key={Math.random()}>
                                  <NavLink to={child.path} onClick={child?.onClick}>
                                      <span className="icon" style={{ background: pathname.replace("/", "") === (child.path).replace("/", "") ? color : ""}}>
                                        {child.icon}
                                      </span>
                                      <span className="label">{child.label}</span>
                                  </NavLink>
                              </Menu.Item>
                          );
                      }else if(child.process){
                          return (
                              <Menu.Item key={Math.random()} >
                                  <a href="javascript:;" onClick={child?.onClick}>
                                       <span className="icon" style={{ background: pathname.replace("/", "") === (child.path).replace("/", "") ? color : ""}}>
                                            {child.icon}
                                        </span>
                                      <span className="label">{child.label}</span>
                                  </a>
                              </Menu.Item>
                          );
                      }

                  })
                }
              </>
          ))
        }
      </Menu>
    </>
  );
}

export default Sidenav;
