import React, { useState } from "react";
import {Card, Col, Layout, Row, Typography} from "antd";
import sendApi from "../Configs/Api";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {PieChartTwoTone} from "@ant-design/icons";

ChartJS.register(ArcElement, Tooltip, Legend);


function Home() {

  const { Title, Text } = Typography;
  const [getServerStatic,setServerStatic] = useState([0,0]);
  const [getStaticCount,setStaticCount] = useState([]);

  const data = {
    labels: ['Kullanılan Ram Mb', 'Kullanılmayan Ram Mb'],
    datasets: [
      {
        label: '',
        data: getServerStatic,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)'
        ],
        borderWidth: 1,
      },
    ],
  }


  const getIntervalStaticData = () => {
    sendApi("/v1/frontend/static")
        .then((result) => {
          console.log(result.data);
          setStaticCount([]);
          result.data.map(item => {
            setStaticCount(getStaticCount => [...getStaticCount,{
              today: item.title,
              title: item.count,
              persent: "Kayıt",
              icon: <PieChartTwoTone />
            }])
          })
        }).catch((error) => {
      console.log("Server Static : ",error);
    })
  }

  React.useEffect(()=>{

    let getIntervalStaticServer = setInterval(()=>{
      sendApi("/v1/frontend/server")
          .then((result) => {
            setServerStatic([
              result.data.memory.used,
              result.data.memory.free
            ])
          }).catch((error) => {
            console.log("Server Static : ",error);
          })
    },500);

    getIntervalStaticData();
    let getIntervalStatic = setInterval(getIntervalStaticData,1500);



    return ()=>{
      clearInterval(getIntervalStaticServer);
      clearInterval(getIntervalStatic);
    }
  },[])

  const { Content } = Layout;

  return (
    <>
      <Content className="site-layout" style={{ background:"transparent", height:"100%", padding: '0 10px', marginTop: 10 }}>
        <Row className="rowgap-vbox" gutter={[24, 0]}>
         <Col md={16}>
           <Row gutter={24}>
             {getStaticCount.map((c, index) => (
                 <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                   <Card bordered={false} className="criclebox ">
                     <div className="number">
                       <Row align="middle" gutter={[24, 0]}>
                         <Col xs={18}>
                           <span>{c.today}</span>
                           <Title level={3}>
                             {c.title} <small className={c.bnb}>{c.persent}</small>
                           </Title>
                         </Col>
                         <Col xs={6}>
                           <div className="icon-box">{c.icon}</div>
                         </Col>
                       </Row>
                     </div>
                   </Card>
                 </Col>
             ))}
           </Row>
         </Col>
          <Col md={8} style={{justifyContent:"center", display:"flex"}}>
            <Pie data={data} />
          </Col>
        </Row>
      </Content>
    </>
  );
}

export default Home;
