

const ApiServer         = "https://app.umex.com.tr";
const ContentServer     = ApiServer + "/upload/";



export {
    ApiServer,
    ContentServer
}