import {CheckCircleOutlined, FieldTimeOutlined, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import { Button, message, Tabs, Drawer, Form, Image, Row, Col, Popconfirm, Space, Table, Card, Tag, Input, Select, Upload, Result, Progress, Divider } from 'antd';
import React, { useState } from 'react';
import sendApi from "../../Configs/Api";
import CreateNewLesson from "./CreateNewLesson";
import CreateNewCategory from "./CreateNewCategory";
import {ContentServer} from "../../Configs/Settings";
import ViewLesson from "./ViewLesson";
import ConfirmApiRequest from "../../Configs/ConfirmApiRequest";

const DetailsCategory = (props) => {

    const [form]                                            = Form.useForm();
    const { TabPane }                                       = Tabs;
    const [getCategory,setCategory]                         = React.useState();
    const [getCreateLesson,setCreateLesson]                 = React.useState(false);
    const [getLessons,setLessons]                           = React.useState([]);
    const [getSubscribers,setSubscribers]                   = React.useState([]);
    const [getViewLessonSerial,setViewLessonSerial]         = React.useState("");
    const [getViewLesson,setViewLesson]                     = React.useState(false);
    const [fileListLogo, setFileListLogo]                   = useState([]);
    const [fileListCover, setFileListCover]                 = useState([]);
    const [loading, setLoading]                             = useState(false);
    const [uploadLoading, setUploadLoading]                 = useState(false);
    const [getKeyOfTab, setKeyOfTab]                        = useState(0);
    const { Option }                                        = Select;
    const [percentView, setPercentView]                     = useState(0);


    const getDataToApi = () => {

        setLessons([]);
        setFileListLogo([]);
        setFileListCover([]);

        sendApi("/v1/frontend/category/subscribers",{ serial: props.serial })
            .then(result =>{

                if(result.status === "success"){
                    setSubscribers([]);
                    result.data.subscribers.map(item=>setSubscribers(getSubscribers => [...getSubscribers,{
                        customer: item.fullName
                    }]));

                }

            }).catch(error =>{
            message.error(error.message);
        });


        sendApi("/v1/frontend/category/details",{ serial: props.serial })
            .then(result => {
                if(result.status === "success"){
                    setCategory(result.data);
                    result.data.lessons.map(item=>setLessons(getLessons => [...getLessons,{
                        serial: item.serial,
                        cover: item.cover,
                        title:item.title,
                        status: item.status
                    }]));

                    form.setFields([
                        {
                            name:['title'],
                            value:result.data.category.title
                        },
                        {
                            name:['status'],
                            value:result.data.category.status
                        },
                        {
                            name:['certificate1'],
                            value:result.data.category.certificate_1
                        },
                        {
                            name:['certificate2'],
                            value:result.data.category.certificate_2
                        },
                        {
                            name:['certificate3'],
                            value:result.data.category.certificate_3
                        },
                    ]);

                    setFileListLogo([{
                        uid: result?.data?.category.serial+"_logo",
                        name: result?.data?.category.logo,
                        status: 'done',
                        url: ContentServer+result?.data?.category.logo,
                    }]);

                    setFileListCover([{
                        uid: result?.data?.category.serial+"_cover",
                        name: result?.data?.category.cover,
                        status: 'done',
                        url: ContentServer+result?.data?.category.cover,
                    }]);

                }else{
                    message.error(result.message,.5);
                }
            })
            .catch(error =>  message.error(error.message,.5));

    }

    React.useEffect(()=>{

        if(props.visible){
            getDataToApi();
        }

        return ()=> {
            setCreateLesson(false);
            setLessons([]);
        }
    },[props.serial,props.visible]);

    const DescriptionItem = ({ title, content }) => (<div className="site-description-item-profile-wrapper">
                <p className="site-description-item-profile-p-label">{title}:</p>
                {content}
            </div>);

    const columns = [
        {
            title: 'Kapak',
            dataIndex: 'cover',
            key: 'cover',
            render: (text) => <Image width={100} src={ContentServer+text}/>,
        },
        {
            title: 'Başlık',
            dataIndex: 'title',
            key: 'title',
            render: (text) => text,
        },
        {
            title: 'Yayın Durumu',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                <>
                    {record.status === "active" ? (<Tag color={"green"}>YAYINDA</Tag>) : (<Tag color={"volcano"}>PASİF</Tag>)}
                </>
            ),
        },
        {
            title: 'İşlem',
            key: 'status',
            render: (_, record) => {

                return (<Button type={"primary"} onClick={()=>{
                    setViewLessonSerial(record.serial);
                    setViewLesson(true);
                }}>Detay Git</Button>);
            },
        },
    ];

    const propsLogo = {
        accept:".jpg,.JPEG,.JPEG,.jpeg,.png,.PNG",
        maxCount:1,
        onRemove: (file) => {
            const index = fileListLogo.indexOf(file);
            const newFileList = fileListLogo.slice();
            newFileList.splice(index, 1);
            setFileListLogo(newFileList);
        },
        beforeUpload: (file) => {
            setFileListLogo([file]);
            return false;
        },
        fileList: fileListLogo,
    };

    const propsCover = {
        accept:".jpg,.JPEG,.JPEG,.jpeg,.png,.PNG",
        maxCount:1,
        onRemove: (file) => {
            const index = fileListCover.indexOf(file);
            const newFileList = fileListCover.slice();
            newFileList.splice(index, 1);
            setFileListCover(newFileList);
        },
        beforeUpload: (file) => {
            setFileListCover([file]);
            return false;
        },
        fileList: fileListCover,
    };

    const categorySubscribers       = [
        {
            title: 'Müşteri',
            dataIndex: 'customer',
            key: 'customer'
        }
    ];

    const onFinish = (values) => {

        setLoading(true);
        setUploadLoading(true);

        sendApi("/v1/frontend/category/update",{
            title: values.title === undefined ? "" : values.title,
            status: values.status === undefined ? "" : values.status,
            certificate_1: values.certificate1 === undefined ? "" : values.certificate1,
            certificate_2: values.certificate2 === undefined ? "" : values.certificate2,
            certificate_3: values.certificate3 === undefined ? "" : values.certificate3,
            logo: values.logo === undefined ? "" : values.logo.file,
            cover: values.cover === undefined ? "" : values.cover.file,
            serial: props.serial
        },(income)=>{
            if(income > 0){
                setPercentView(income);
            }
            if(income >= 100){
                setPercentView(0);
                setUploadLoading(false)
            }
        }).then(result=> {
            setLoading(false);
            if(result.status === "success"){
                getDataToApi();
                message.success("Kayıt Güncellendi");
                props?.result(true);
            }else{
                message.error(result.message);
            }
        }).catch(error=>{
            setLoading(false);
            message.error(error.message);
        });

    };

    return (
        <>

            <Drawer
                title={getCategory?.category.title}
                width={"80%"}
                onClose={props.onClose}
                visible={props.visible}
                extra={
                    <>
                        {  parseInt(getKeyOfTab) === 1 &&
                            <Space>
                                <ConfirmApiRequest
                                    server={"/v1/frontend/category/delete"}
                                    params={{
                                        serial: props.serial
                                    }}
                                    result={()=>{
                                        props?.result(true);
                                        props?.onClose(false);
                                    }}
                                />
                            </Space>
                        }
                        {  parseInt(getKeyOfTab) === 2 &&
                            <Space>
                                <Button type={"primary"} onClick={()=>{ setCreateLesson(true) }}>Eğitim Ekle</Button>
                            </Space>
                        }
                        {  parseInt(getKeyOfTab) === 3 &&
                            <Space>
                                <Button type={"success"} onClick={()=>{ form.submit(); }} loading={loading}>Güncelle</Button>
                            </Space>
                        }

                    </>
                }>
                <Row >
                    <Col span={24 }>
                        <Tabs defaultActiveKey="1"  onChange={key=>setKeyOfTab(key)}>
                            <TabPane tab="Detaylar" key="1">
                                <Space
                                    direction="vertical"
                                    size="middle"
                                    style={{
                                        display: 'flex',
                                    }}>
                                    <Card title="Kategori Detayları" size="small">
                                        <Row>
                                            <Col span={12}>
                                                <DescriptionItem title="Başlık" content={getCategory?.category.title} />
                                            </Col>
                                            <Col span={12}>
                                                <DescriptionItem title="Kategori Kodu" content={getCategory?.category.serial} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <DescriptionItem title="Oluşturan" content={getCategory?.category.admin.fullName} />
                                            </Col>
                                            <Col span={12}>
                                                <DescriptionItem title="Oluşturma Tarihi" content={getCategory?.category.time} />
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card title="Sertifika İçeriği" size="small">
                                        <Row>
                                            <Col span={24}>
                                                <DescriptionItem title="Sertifika Yazı 1" content={getCategory?.category.certificate_1} />
                                            </Col>
                                            <Col span={24}>
                                                <DescriptionItem title="Sertifika Yazı 2" content={getCategory?.category.certificate_2} />
                                            </Col>
                                            <Col span={24}>
                                                <DescriptionItem title="Sertifika Yazı 3" content={getCategory?.category.certificate_3} />
                                            </Col>
                                        </Row>
                                    </Card>
                                    {
                                        getCategory?.category &&
                                        <Card title="Görsel İçerik" size="small">
                                            <Row>
                                                <Col span={12} style={{display:"flex", borderRadius:5, overflow:'hidden', alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                                    <Col style={{ backgroundColor:"rgba(0,0,0,0.27)", display:"flex",width:440, height:250, borderRadius:5, overflow:'hidden',alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                                        <Image
                                                            style={{borderRadius:5}}
                                                            height={"100%"}
                                                            src={ContentServer+getCategory.category.logo}
                                                        />
                                                    </Col>
                                                </Col>
                                                <Col span={12} style={{display:"flex", borderRadius:5, overflow:'hidden', alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                                    <Col style={{display:"flex",backgroundColor:"rgba(0,0,0,0.27)", width:440, height:250, borderRadius:5, overflow:'hidden',alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                                                        <Image
                                                            style={{borderRadius:5}}
                                                            height={"100%"}
                                                            src={ContentServer+getCategory.category.cover}
                                                        />
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12} >
                                                    <Divider>Marka Logo</Divider>
                                                </Col>
                                                <Col span={12} >
                                                    <Divider>Detay Görsel</Divider>
                                                </Col>
                                            </Row>
                                        </Card>
                                    }
                                </Space>
                            </TabPane>
                            <TabPane tab="Eğitim İçerikleri" key="2">
                                <Table  columns={columns} dataSource={getLessons} />
                            </TabPane>
                            <TabPane tab="Müşteriler" key="4">
                                <Table columns={categorySubscribers} dataSource={getSubscribers} />
                            </TabPane>
                            <TabPane tab="Ayarlar" key="3">

                                { uploadLoading &&
                                    <Result
                                        icon={<Progress
                                            type="circle"
                                            strokeColor={{
                                                '0%': '#108ee9',
                                                '100%': '#87d068',
                                            }}
                                            percent={percentView}/>}
                                        title="Eğitim Yükleniyor, Lütfen Bekleyiniz."
                                    />
                                }

                                {
                                    !uploadLoading &&
                                    <Form  form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
                                        <Row gutter={16}>
                                            <Col span={15}>
                                                <Form.Item name="title" label="Başlık" rules={[{required: true, message:"Lütfen bir başlık giriniz"}]}>
                                                    <Input placeholder={"Lütfen bir başlık giriniz"}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={9}>
                                                <Form.Item name="status" label="Yayın Durumu" rules={[{required: true, message:"Lütfen yayın durumu seçiniz"}]}>
                                                    <Select placeholder="Seçim yapınız"  allowClear>
                                                        <Option value="active">Aktif</Option>
                                                        <Option value="passive">Pasif</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="certificate1" label="Sertifika Alan 1" rules={[{required: true, message:"Lütfen sertifika alan 1 içeriği giriniz" }]}>
                                                    <Input showCount maxLength={75} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="certificate2" label="Sertifika Alan 2" rules={[{required: false, message:"Lütfen sertifika alan 2 içeriği giriniz" }]}>
                                                    <Input showCount maxLength={75} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="certificate3" label="Sertifika Alan 3" rules={[{required: false, message:"Lütfen sertifika alan 3 içeriği giriniz" }]}>
                                                    <Input showCount maxLength={75} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item name="logo" label="Logo Görseli" rules={[{required: false, message:"Lütfen logo görseli seçiniz" }]}>
                                                    <Upload {...propsLogo}>
                                                        <Button icon={<UploadOutlined />}>Logo Seçiniz</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="cover" label="Kapak Görseli" rules={[{required: false, message:"Lütfen kapak görseli seçiniz"}]}>
                                                    <Upload {...propsCover}>
                                                        <Button icon={<UploadOutlined />}>Kapak Seçiniz</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                }
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Drawer>

            <CreateNewLesson
                visible={getCreateLesson}
                serial={props.serial}
                onClose={()=>{
                    setCreateLesson(false);
                }}
                result={(status)=>{
                    if(status){
                        getDataToApi();
                    }
                }}
            />

            <ViewLesson
                visible={getViewLesson}
                category={props.serial}
                serial={getViewLessonSerial}
                onClose={()=>{
                    setViewLesson(false);
                }}
                result={(status)=>{
                    getDataToApi();
                }}
            />
        </>
    );
};


export default DetailsCategory;