import React from "react";
import {Switch, Route, Redirect, NavLink} from "react-router-dom";
import SignIn from "./Pages/SignIn";
import Main from "./Components/Layout/Main";
import "antd/dist/antd.css";
import "./Assets/styles/main.css";
import "./Assets/styles/responsive.css";
import {RouterConfig} from "./Configs/Router";
import {RecoilRoot} from "recoil";
import NotFound from "./Pages/NotFound";
import sendApi from "./Configs/Api";

function App() {

  const [getToken, setToken] = React.useState(() => {

      const getCurrentToken = localStorage.getItem("tokenJWT");

      if(getCurrentToken !== null){
        return getCurrentToken;
      }

      return false;

  });

    if(getToken){
        setInterval(()=>{
            sendApi("/v1/frontend/heart")
                .then(result=>{
                    if(result.status === "error"){
                        localStorage.removeItem("tokenJWT");
                        window.location.href = "/sign-in";
                    }
                })
                .catch(err=>console.log("Err",err));
        },1500);
          return (
              <div className="App">
                  <RecoilRoot>
                      <Switch>
                          { RouterConfig.map(item => item.children.map(child => child.route ? (<Route key={Math.random().toString()} exact={child.exact} path={child.path} component={ ()=> <Main> <child.component/> </Main> } />) : null) ) }
                          <Route >
                              <Main> <NotFound to={"/dashboard"} buttonTitle={"Anasayfa"} /></Main>
                          </Route>
                      </Switch>
                  </RecoilRoot>
              </div>
          );
    }else{
        return (
            <div className="App">
                <RecoilRoot>
                    <Switch>
                        <Route path="/sign-in" exact component={SignIn} key={"1"} />
                        <Route >
                            <NotFound to={"/sign-in"} buttonTitle={"Giriş Yap"} />
                        </Route>
                    </Switch>
                </RecoilRoot>
            </div>
        );
    }
}

export default App;
